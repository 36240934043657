import React from 'react'

const HR: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>
> = ({ children, className, ...other }) => {
  return (
    <hr
      className={'my-3 border-gray-bg-2' + (className ? ` ${className}` : '')}
      {...other}
    />
  )
}

export default HR
